<template>
  <div v-if="dashboard">
    <div class="head-container h sp" style="background:#909399">
      <div class="padding-0-20 c h">
        <img :src="codeUrl1" />
        <img :src="codeUrl2" />
        图一 图二
        <h1 class="padding-0-20 fc-w">欢迎使用DMS</h1>
      </div>
       <div class="flex"></div>
      <div class="padding-10 c h">
        <div class="fc-w"><h3>经销商数量已达：</h3></div>
        <div class="fs-large">1520</div>
      </div>
    </div>

    <div class="flex scroll-able scroll-container-main" v-loading="loading">
      <div class="padding-10">
        <b>地理信息图</b>
      </div>
      <div ref="map" class="flex bc-l v"></div>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import Counter from "vue-count-to";
import request from "@/utils/request";

export default {
  mixins: [initData],
  components: { Counter },
  props: {
    dashboard: Boolean
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      count: {
        total: 0,
        wholesale: 0,
        retail: 0
      },
      chart: {
        map: null
      }
    };
  },
  methods: {
    toQuery() {
      this.drawMapChart();
    },

    drawMapChart() {
      request
        .get("api/analy/sale/province", { params: this.getQueryParams() })
        .then(res => {
          let ds = [
              { name: "北京市", value: 0 },
              { name: "天津市", value: 0 },
              { name: "上海市", value: 0 },
              { name: "广东省", value: 0 },
              { name: "湖南省", value: 0 },
              { name: "山东省", value: 0 },
              { name: "山西省", value: 0 },
              { name: "福建省", value: 0 },
              { name: "江苏省", value: 0 },
              { name: "海南省", value: 0 },
              { name: "青海省", value: 0 },
              { name: "四川省", value: 0 },
              { name: "黑龙江省", value: 0 },
              { name: "甘肃省", value: 0 },
              { name: "云南省", value: 0 },
              { name: "陕西省", value: 0 },
              { name: "安徽省", value: 0 },
              { name: "辽宁省", value: 0 },
              { name: "河北省", value: 0 },
              { name: "河南省", value: 0 },
              { name: "湖北省", value: 0 },
              { name: "江西省", value: 0 },
              { name: "吉林省", value: 0 },
              { name: "贵州省", value: 0 },
              { name: "浙江省", value: 0 },
              { name: "重庆市", value: 0 },
              { name: "宁夏回族自治区", value: 0 },
              { name: "广西壮族自治区", value: 0 },
              { name: "内蒙古自治区", value: 0 },
              { name: "西藏自治区", value: 0 },
              { name: "新疆维吾尔自治区", value: 0 }
            ],
            max = 0;
          (res.salelist || 0).forEach(o => {
            let item = ds.find(d => {
              return d.name === o[1];
            });
            if (item) {
              item.value += o[3] / 100;
              if (item.value > max) max = Math.ceil(item.value);
            }
          });
          (res.shopSalelist || 0).forEach(o => {
            let item = ds.find(d => {
              return d.name === o[1];
            });
            if (item) {
              item.value += o[3] / 100;
              if (item.value > max) max = Math.ceil(item.value);
            }
          });

          if (!this.chart.map) {
            this.chart.map = echarts.init(this.$refs.map, "light");
          }
          this.chart.map.clear();
          request({
            url: "//" + window.location.host + "/static/mapdata/china.json",
            method: "get"
          }).then(res => {
            echarts.registerMap("china", res);
            this.chart.map.setOption({
              title: {
                top: 10,
                left: 10,
                text: "全国销售热力图",
                textStyle: {
                  fontSize: 14,
                  fontFamily: "PingFang SC"
                }
              },
              tooltip: {
                trigger: "item",
                formatter: "{b}<br/>{a}: {c}"
              },
              grid: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10
              },
              visualMap: {
                min: 0,
                max: max,
                text: ["High", "Low"],
                left: 20,
                bottom: 20,
                realtime: false,
                calculable: true,
                inRange: {
                  color: ["lightskyblue", "yellow", "orangered"]
                }
              },
              series: [
                {
                  name: "总销售量",
                  type: "map",
                  mapType: "china",
                  top: 30,
                  left: 30,
                  right: 30,
                  bottom: 30,
                  itemStyle: {
                    normal: { label: { show: true } },
                    emphasis: { label: { show: true } }
                  },
                  data: ds
                }
              ]
            });
          });
        });
    }
  },
  mounted() {
    setTimeout(_ => {
      this.toQuery();
    }, 1000);
  }
};
</script>

<style lang="less" scoped>
.count-item {
  margin: 0 100px 0 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  .num {
    display: block;
    font-size: 22px;
    margin-top: 5px;
  }
}
</style>